import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Icon,
  Select,
  MenuItem,
  styled,
  Box,
  Stack,
} from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { Step, StepContainer, NextStepButton, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectCaratClassification,
  selectItemType,
  updateCarat,
  updateCaratClassification,
  updateCaratRangeTo,
} from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import CaratWeight from '../../popup/carat_weight';
import { suggestCaratThreshold } from '../../../data/constants';
import { CaratSizeRanges, CLASSIFICATION, stoneClassifications } from '../../../data/ItemDetails';
import { FormContent } from '../../../styles/commonText';
import { toCamelCase } from '../../../lib/commonUtils';
import { StyledButton } from '../../common/ButtonsList';
import StyledInfoAlert from '../../common/StyledInfoAlert';
import CTAButton from '../../common/ContinueButton';

const InitialWeightRange = '{ "from": 0, "to": 0, "text": "" }';

const NaturalIcon = Icon.createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 34 31" fill="none">
    <g id="objects35">
      <path
        id="Vector"
        d="M12.1301 10.9348L7.26049 7.02173L3.78223 12.2391L9.34744 30.5H17.6953L12.1301 10.9348Z"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.7387 17.1957L9.20826 11.9784L7.95605 10.9348"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M23.9569 10.2826L23.2613 4.41304L19.783 0.5L14.9134 5.71739L14.2178 18.2392"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M21.0344 10.2826L20.7562 6.10876L19.7822 4.93481"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M17.6952 30.5H26.0431L32.9996 10.9348L30.9126 4.41309L24.6517 8.32613L17.1387 28.2827"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M27.4346 18.1087L30.0781 10.674L29.3824 8.19568"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M28.4092 23.8478L33.0005 20.0652L31.6092 30.5H26.0439L28.4092 23.8478Z"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M10.7391 30.5H3.78261L1 18.7609L8.65217 28.0218"
        stroke="CurrentColor"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
  </svg>,
  'Natural',
);

const LabGrownIcon = Icon.createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" fill="none" />
    <path
      d="M28 14.8043H4"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 14.8043L16 31.5L28 14.8043"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3047 14.8043L16.0003 31.5L20.696 14.8043"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 12L20.5 13"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5005 12.5L13.0439 13.087"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9.4131V12.5001"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 1L16 14.5"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 0.5L25 4"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 0.5L16 14.5"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'LabGrown',
);
const GemStoneIcon = Icon.createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 32" fill="none">
    <rect width="24" height="32" fill="none" />
    <g id="Design guidelines">
      <path
        d="M-5961 -1002C-5961 -1024.09 -5943.09 -1042 -5921 -1042H873C895.091 -1042 913 -1024.09 913 -1002V4891C913 4913.09 895.091 4931 873 4931H-5921C-5943.09 4931 -5961 4913.09 -5961 4891V-1002Z"
        fill="none"
      />
      <path
        d="M-5921 -1041H873V-1043H-5921V-1041ZM912 -1002V4891H914V-1002H912ZM873 4930H-5921V4932H873V4930ZM-5960 4891V-1002H-5962V4891H-5960ZM-5921 4930C-5942.54 4930 -5960 4912.54 -5960 4891H-5962C-5962 4913.64 -5943.64 4932 -5921 4932V4930ZM912 4891C912 4912.54 894.539 4930 873 4930V4932C895.644 4932 914 4913.64 914 4891H912ZM873 -1041C894.539 -1041 912 -1023.54 912 -1002H914C914 -1024.64 895.644 -1043 873 -1043V-1041ZM-5921 -1043C-5943.64 -1043 -5962 -1024.64 -5962 -1002H-5960C-5960 -1023.54 -5942.54 -1041 -5921 -1041V-1043Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <g id="static">
        <rect width="1440" height="900" transform="translate(-844 -527)" fill="none" />
        <g id="Content - 01">
          <g id="Quizz Module">
            <g id="Content Wrapper">
              <g id="Content">
                <g id="Frame 2">
                  <g id="Frame 1">
                    <g id="Base/ Chips">
                      <rect x="-52" y="-16" width="128" height="93" rx="6" fill="none" />
                      <g id="Layer_1">
                        <path
                          id="Vector 7"
                          d="M9 6.5H15M9 6.5L7 8.5M9 6.5L6 0.5M15 6.5L17 8.5M15 6.5L18 0.5M17 8.5V23.5M17 8.5L23 6M17 23.5L15 25.5M17 23.5L23 26.5M15 25.5H9M15 25.5L18 31.5M9 25.5L7 23.5M9 25.5L6.5 31.5M7 23.5V8.5M7 23.5L1 26.5M7 8.5L1 5.5M18 0.5L23 6M18 0.5H6M23 6V26.5M23 26.5L18 31.5M18 31.5H6.5M6.5 31.5L1 26.5M1 26.5V5.5M1 5.5L6 0.5"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'GemStone',
);

const caratClassificationList = [
  {
    text: CLASSIFICATION.NATURAL,
    img: NaturalIcon,
    eventText: 'natural',
  },
  {
    text: CLASSIFICATION.LAB_GROWN,
    img: LabGrownIcon,
    eventText: 'labGrown',
  },
  {
    text: CLASSIFICATION.GEMSTONE,
    img: GemStoneIcon,
    eventText: 'gemstone',
  },
];

const bucketTypes = ['Loose diamond', 'Ring'];

const InlineWrapper = styled(Box)`
  display: inline-flex;
`;
const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.secondary.main,
  border: 'none',
  width: '100%',
  '&:hover, &:active': {
    outline: `1px solid ${theme.palette.highlight.main}`,
  },
  '& fieldset': {
    border: 'none',
  },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '90%',
  padding: theme.spacing(1, 1),
}));

function Weight({ active, next, stepName, idx, prev, title, stepCaption, props }: StepProps) {
  const [caratWeightPopupVisible, showCaratWeight] = useState<boolean>(false);
  const [weight, setWeight] = useState<number>(0);
  const [weightRange, setWeightRange] = useState<string>(InitialWeightRange);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [nextAvailability, setNextAvailability] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  const itemType = useAppSelector(selectItemType);
  const classification = useAppSelector(selectCaratClassification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!active) return;

    if (itemType === 'Ring') {
      GA.stepView(
        stepName,
        undefined,
        undefined,
        undefined,
        undefined,
        'ringSubmissionFunnel',
        'caratStepView',
      );
    } else {
      GA.stepView(stepName);
    }
  }, [active]);

  const validate = (value: string): void => {
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue) || parsedValue >= 20 || parsedValue <= 0) {
      setWeight(0);
      setIsValid(false);
      setNextAvailability(false);
    } else {
      setWeight(parsedValue);
      setIsValid(true);
      setNextAvailability(true);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      validate(e.target.value);
      if (isValid) {
        if (+e.target.value >= suggestCaratThreshold) {
          showCaratWeight(true);
          return;
        }
      }

      if (isValid && nextAvailability) {
        dispatch(updateCarat(weight));
        next(e);
      }
    }
  };

  const nextClb = (e: React.MouseEvent<HTMLButtonElement>, value: number) => {
    GA.submitNext(stepName, value.toString(), weight.toString(), 'caratDecimalPopup');
    dispatch(updateCarat(value));
    next(e);
  };

  const handleSelectRange = (e: any) => {
    setWeightRange(e.target.value);

    const range = JSON.parse(e.target.value);
    const weightFrom = range.text === 'Not Sure' || !range.from ? 0 : range.from;
    setWeight(weightFrom);
    setIsValid(true);
    setNextAvailability(true);
  };

  const getDropdown = () => (
    <Stack maxWidth={410} gap={3}>
      <StyledSelect
        labelId="carat-step-select-label"
        id="carat-step-select"
        data-automation="carat-step-select"
        value={weightRange}
        onChange={(e: any) => {
          handleSelectRange(e);
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={() =>
          JSON.parse(weightRange).text.length !== 0
            ? JSON.parse(weightRange).text
            : 'Select carat range'
        }
        displayEmpty
      >
        <StyledMenuItem disabled divider value={InitialWeightRange}>
          <Typography>
            <em>Select carat range</em>
          </Typography>
        </StyledMenuItem>
        {CaratSizeRanges.map((range) => (
          <StyledMenuItem
            divider
            key={`carat-step-select-${range.text}`}
            value={`{"from": ${range.from}, "to": ${range.to}, "text": "${range.text}"}`}
          >
            <Typography>{range.text}</Typography>
          </StyledMenuItem>
        ))}
      </StyledSelect>
      <Stack
        direction="row"
        gap={1}
        flexWrap="nowrap"
        justifyContent="center"
        width="100%"
        height={90}
      >
        {caratClassificationList.map((classificationType) => (
          <StyledButton
            size="large"
            data-automation={`carat-classification-${classificationType.eventText}`}
            key={classificationType.text}
            selected={classificationType.text === classification}
            onClick={() => dispatch(updateCaratClassification(classificationType.text))}
            sx={{ height: '100%', width: '100%', maxWidth: 130 }}
          >
            <Stack justifyContent="center" alignItems="center" gap={1} width="100%">
              <classificationType.img />
              <Typography variant="body1" noWrap>
                {classificationType.text}
              </Typography>
            </Stack>
          </StyledButton>
        ))}
      </Stack>
    </Stack>
  );

  const getInputNumberField = () => (
    <InlineWrapper>
      <TextField
        data-automation="central-carat-weight-input"
        inputProps={{ inputMode: 'decimal', type: 'number', step: 0.1, min: 0.1, max: 20.0 }}
        error={!isValid}
        variant="standard"
        placeholder="0.0 ct."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validate(e.target.value);
          setWeightRange(InitialWeightRange);
        }}
        onKeyDown={handleEnter}
        sx={{ input: { textAlign: 'center', minWidth: '12.2em' } }}
        disabled={bucketTypes.includes(itemType)}
      />
    </InlineWrapper>
  );

  const getForm = () => {
    if (bucketTypes.includes(itemType)) {
      return getDropdown();
    }

    return getInputNumberField();
  };

  const getTip = () =>
    !bucketTypes.includes(itemType) ? (
      <StyledInfoAlert sx={{ marginTop: 3 }}>
        Tip: Most common diamond Carat weight is 0.9CT.
      </StyledInfoAlert>
    ) : null;

  const handleContinueClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!busy) {
      if (weight >= suggestCaratThreshold) {
        showCaratWeight(true);
        return;
      }

      setBusy(true);
      dispatch(updateCarat(weight));

      if (bucketTypes.includes(itemType)) {
        const range = JSON.parse(weightRange);
        const caratClass = _.find(stoneClassifications, { text: classification })?.eventText || '';

        if (range.text) {
          const rangeCamel = range.text.replace(' ct.', '');
          GA.submitCaratWeight(toCamelCase(rangeCamel), caratClass);
          dispatch(updateCaratRangeTo(range.to));
        }
      } else {
        GA.submitNext(stepName, weight.toString());
      }

      next(e);
      setTimeout(() => {
        setBusy(false);
      }, 150);
    }
  };

  return (
    <StepContainer data-automation="central-carat-weight-step">
      <Step>
        <StepTitle
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || 'Great! What is the largest diamond’s carat weight?'}
          stepNotice=""
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <FormContent>{getForm()}</FormContent>
          {getTip()}
          <NextStepButton>
            <CTAButton
              data-automation="central-carat-weight-next-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleContinueClicked(e)}
              disabled={!nextAvailability}
            />
          </NextStepButton>
        </StepGridContainer>
        {itemType === 'Ring' && (
          <Box sx={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
            <StyledInfoAlert sx={{ maxWidth: 400 }}>
              Select the individual weight of the single largest diamond
            </StyledInfoAlert>
          </Box>
        )}

        {caratWeightPopupVisible && (
          <CaratWeight
            next={nextClb}
            setPopupVisible={showCaratWeight}
            itemCarat={weight}
            stepName={stepName}
          />
        )}
      </Step>
    </StepContainer>
  );
}

export default Weight;
