import ZapierAPI from '../services/ZapierAPI';
import { isPromoExperiment } from './experiment';

export const PromoOnRegistrationTerms = `You've locked in extra cash on your sale! Submit your item now and ship by 9/11 to qualify. T&Cs apply* `;

export const registerToPromotion = (email: string) => {
  if (email && isPromoExperiment()) {
    ZapierAPI.registerToPromotion(email);
  }
};
